import dayjs from "dayjs";

export function getStartTime( startTime:Date ) {
    return dayjs(startTime).format("YYYY-MM-DD") + " 00:00:00";
}

export function getEndTime( endTime:Date ){
    return dayjs(endTime).format("YYYY-MM-DD") + " 23:59:59";
}

export function getStartDateTime( startTime:Date ){
    return dayjs(startTime).format("YYYY-MM-DD HH:mm:ss");
}

export function getEndDateTime( startTime:Date ){
    return dayjs(startTime).format("YYYY-MM-DD HH:mm:ss");
}