import axios from 'axios'


const context = "/deviceservice";

/***
 * 获取血压分页数据
 */
export function getBloodPressurePagination( data:any ){
   return axios.get(`${context}/pressure/admin/pagination`, {params: data});
}

/***
 * 删除血压数据
 */
export function removeBloodPressure( data:any ){
   return axios.delete(`${context}/pressure/${data.id}`);
}

/***
 * 获取体温分页数据
 */
export function getTwtTemplatePagination( data:any ) {
   return axios.get(`${context}/device/twt/pagination`, {params: data});
}

/***
 * 删除体温数据
 */
export function removeTemplate( data:any ){
   return axios.delete(`${context}/device/twt/${data.id}` ,{params: data} )
}

/***
 * 带出体温贴数据
 */
export function downloadTemerateData(data:any){
   return axios({
      url: `${context}/device/twt/downloadReportData`,
      method: "POST",
      responseType: "blob",
      data:data
   })
}


